import React from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
import styled from 'styled-components'
import * as colors from './colors'
import * as typography from './typography'
import TopicBit from '../components/TopicBit'

const Component = styled.div`
  grid-column: 2;

  h1 {
    ${typography.heading1}
  }

  p {
    ${typography.baseUI()}
    font-size: 22px !important;
    margin: 12px 0;
    font-size: 20px;
    color: ${colors.N750};
  }
`

const PostDetails = ({ post }) => {
  const { /*id,*/ title, description: { description }, /*publishDate,*/ topic } = post

  return (
    <Component>
        {/* <div>{id}</div> */}
        <TopicBit key={topic.slug} label={topic.slug} />
        <h1>{title}</h1>
        <p>{description}</p>
        {/* <div>{publishDate}</div> */}
    </Component>
  )
}

// PostDetails.propTypes = {
//   label: PropTypes.string.isRequired
// }

// PostDetails.defaultProps = {
//   primary: true,
// }

export default PostDetails
