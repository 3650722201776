import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Container from '../components/Container'
import PostDetails from '../components/PostDetails'
import PostBody from '../components/PostBody'
// import TopicBit from '../components/TopicBit'

const PostHero = styled(Img)`
  grid-column: 1 / 4;
  width: 100%;
  max-width: 100ch;
  justify-self: center;
  margin: 24px 0;
`

const Article = styled.article`
  font-size: 20px; /* needed to determine ch */
  display: grid;
  grid-template-columns:
    minmax(1.2rem, 1fr)
    minmax(0, 65ch)
    minmax(1.2rem, 1fr);
`

const PostTemplate = ({ data, pageContext }) => {
  console.log(data)
  console.log(pageContext)

  const post = data.contentfulPost
  const { title, /*description: { description },*/ heroImage, body, /*publishDate, topic*/ } = post
  
  // const previous = pageContext.prev
  // const next = pageContext.next
  // const { basePath } = pageContext

  // let ogImage
  // try {
  //   ogImage = heroImage.ogimg.src
  // } catch (error) {
  //   ogImage = null
  // }

  return (
    <Layout>
      <SEO
        title={title}
        description={null}
        TODO_image={heroImage}
      />
      <Container>
        <Article>      
          {/* post details */}
          <PostDetails post={post} />
          {/* <div>
            <div>{post.id}</div>
            <div>{title}</div>
            <div>{description}</div>
            <div>{publishDate}</div>
          </div> */}

          {/* post hero */}
          <PostHero
            alt={title}
            fluid={heroImage.fluid}
          />

          {/* post topic(s) */}
          {/* <div>
            {
              tags.map((tag) => (
                <TopicBit key={tag.slug} label={tag.slug} />
              ))
            }
          </div> */}

          <PostBody json={body.json} />

          {/* {tags && <TagList tags={tags} basePath={basePath} />} */}
          {/* <PostDetails
            date={publishDate}
            timeToRead={body.childMarkdownRemark.timeToRead}
          /> */}
          {/* <PageBody body={body} /> */}

          {/* post links */}      
          {/* <PostLinks previous={previous} next={next} basePath={basePath} /> */}
        </Article>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      heroImage {
        title
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      body {
        json
      }
      publishDate(formatString: "MMMM Do, YYYY")
      topic {
        slug
        title
      }    
    }
  }
`

export default PostTemplate
