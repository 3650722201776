import React from 'react'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import * as colors from './colors'
import * as typography from './typography'

const Paragraph = styled.p`
  ${typography.text};
  text-justify: inter-word; 
`

const Quote = styled.blockquote`
  margin-left: 24px;
  
  p {
    ${typography.blockQuote};
    ::first-letter {
    }
  }

  code {
    ${typography.codeBlock};
    background-color: ${colors.N800};
    border-radius: 4px;
    display: block;
    margin-left: -24p
    overflow: auto;        
    padding: 12px;
    resize: vertical;
    unicode-bidi: embed;
    white-space: pre; 

    ::selection { 
      background-color: ${colors.N700};
    }
  }
`

const Code = styled.code`
  ${typography.code};
  display: inline;
`

const options = {
  // renderText: text => {
  //   return text.split('\n').reduce((children, textSegment, index) => {
  //     return [...children, index > 0 && <br key={index} />, textSegment];
  //   }, []);
  // },
  renderMark: {
    [MARKS.BOLD]: text => <strong>{text}</strong>,
    [MARKS.ITALIC]: text => <em>{text}</em>,
    [MARKS.CODE]: text => <Code>{text}</Code>,
  },
  renderNode: {
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      // console.log(node)
      const { title, description, file } = node.data.target.fields
      const mimeType = file['en-US'].contentType
      const mimeGroup = mimeType.split('/')[0]

      switch (mimeGroup) {
        case 'image':
          return <img
            title={ title ? title['en-US'] : null}
            alt={description ?  description['en-US'] : null}
            src={file['en-US'].url}
            style={{maxWidth: '100%', gridColumn: '1 / 4', border: '0px dashed red'}}
          />
        default:
          return <span style={{backgroundColor: 'red', color: 'white'}}> {mimeType} embedded asset </span>
      }
    }
  },
}

const StyledPostBody = styled.section`
  grid-column: 2;
  margin-bottom: 48px;

  h2 {
    ${typography.heading2};
  }

  h3 {
    ${typography.heading3};
  }

  ul {
    list-style: circle;
  }

  li p {
    margin: 8px;
  }

  img {
    border-radius: 4px;
  }

  hr {
    border: 1px solid ${colors.primary};
    border-radius: 2px;
    height: 2px;
    margin: 32px auto;
    width: 128px;
  }

  a, a:visited, a:active {
    color: ${colors.link};
    __border-bottom: 2px solid ${colors.link};
    __text-decoration: underline;

    ::after {
      content: "⤻";
    }
  }
`

const PostBody = ({json}) => {
  const body = documentToReactComponents(json, options)

  return (
    <StyledPostBody>{body}</StyledPostBody>
  )
}

export default PostBody